import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Block from './common/block'
import StatsActivityItem from './common/stats-activity-item'
const Title = styled.h3`
   & + .text-center {
        margin-top: -1.8rem;
   }
` 
const StatsActivity = (props: any) => {
    const data = props.data.stats || [];
    const className = props.className ?  props.className : ''
    const activity = data.stats_activity || []
    const title = data.stats_activity_title || null
    const link = data.stats_link || null
  return (
    <>
        <Block className={'stats-acitivty-block  ' + props.background + ' ' + className }   >
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-8'>
                        <Title className='text-center mb-5 pb-2'>{title}</Title>
                        {
                            activity.length ? (
                                <div className='row'>
                                    {
                                        activity.map((activity: any)=>(
                                        <div className='col-lg-6' key={activity.id}>
                                            <StatsActivityItem data={activity}/>
                                        </div> 
                                    ))
                                    }
                                </div>
                            ) : null
                        }
                        {
                            link ? (
                                <div className='text-center'>
                                    <Link className='link-more' to={link.url}>{link.text} <span>➜</span></Link>
                                </div>
                            ) : null
                        }
                    </div>
                </div> 
            </div> 
        </Block> 

    </>
  )
}
export default StatsActivity
