import * as React from 'react'
import Block from './common/block'
import styled from 'styled-components'
import { breakpointLg, breakpointNotDesktop} from '../design/responsive/breakpoint'
import { borderRadius} from '../design/spacing/margin'
import { yellow} from '../design/colors/brand'
const Container = styled.div`
    padding: 6rem 2rem;
    border-radius: ${borderRadius};
    h2 {
        color: ${yellow}
    }
    @media (max-width: ${breakpointLg}) {
        padding: 5.5rem 2rem;
        h2 {
            font-size: 3.6rem;
        }
        .h3 {
            font-size: 2.4rem;
        }
    }
    @media (min-width: ${breakpointNotDesktop}) {
        .prefooter_buttons {
            .prefooter__button {
                min-width: 30rem;
            }
        }
    } 
`;
const Text = styled.div`
    max-width: 820px;
    margin: 0 auto 2.5rem; 
    @media (max-width: ${breakpointLg}) {
        font-size: 2.4rem;
    }
` 
const Button = styled.a`
    min-width: 40rem;
    @media (max-width: ${breakpointLg}) {
        padding-left: 2rem!important;
        padding-right: 2rem!important;
        min-width: 0;
        width: 100%;
    }
` 

const PreFooter = (props: any) => {
    const data = props.data.prefooter_data
    const buttonGroup = data.links.length > 1 ? 'prefooter_buttons ' : ''
  return (
    <>
        <Block className={'prefooter text-center p-0 mb-m ' } >
            <div className='container'>
                <Container  className={'prefooter__container ' + ' ' + props.background}>
                    <Text className='prefooter__text'>
                        <h2>{data.title}</h2>
                        <div className='mb-0 h3'>
                            {data.content}
                        </div>
                    </Text>
                    <div className={buttonGroup + ' d-flex align-items-center buttons justify-content-center '}>
                    {
                        data.links.map((link: any)=>(
                            <Button className={'prefooter__button btn btn-primary btn-lg ' + link.button_style}  href={(link.url) ? link.url : ''} key={link.id}>{link.text}</Button>
                        ))
                    } 
                    </div> 
                </Container>
            </div>
        </Block>
    </>
  )
}

export default PreFooter
