import * as React from 'react'
import {Link} from "gatsby"; 
import Block from './common/block'
const IconLink = (props: any) => {
    const data = props.data.icons
    const className = props.className ?  props.className : ''
    return (
        <>
            <Block className={'tpl-block icons-link-block text-center ' + props.background + ' ' + className } >
                <div className="container">
                    {
                        data.title ? (
                            <h5 className='icons-link-block__title'>{data.title}</h5>
                        ) : null
                    }

                    <ul className="list-check star-benefits row">
                        {
                            data.icon_link.map((dt: any)=>(
                                <li className="list-check__item text-center col-md-4 col-lg-2" key={dt.id}>
                                    {/* list-check__icon */}
                                    <Link to= {dt.link} className="d-block mb-4 ratio ratio-1x1">
                                        <img src={dt.image} alt={dt.title}  />
                                    </Link>
                                    <Link to= {dt.link} className="list-check__text text-decoration-none">
                                        {dt.title}
                                        <span> ➜</span>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                    {
                        data.description? (
                            <div className="icons-link-block__description">
                                <p>{data.description}</p>
                            </div>
                        ) : null
                    }

                </div>
            </Block>
        </>
    )
}

export default IconLink
