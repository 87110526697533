import * as React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import styled from 'styled-components'
import { blue } from '../../design/colors/brand'
import { borderRadius } from '../../design/spacing/margin'
import { breakpointMd} from '../../design/responsive/breakpoint'
const VideoContainer = styled.div`
    .ratio {
        border-radius: ${borderRadius};
        overflow: hidden;
        iframe {
            border-radius:  ${borderRadius};
        }
        @media (max-width: ${breakpointMd}) {
            border-radius: 1.2rem;
            iframe {
                border-radius: 1.2rem;
            }
        }
    }
    &.playing {
        .ratio {
            // background-color: black;
            &:before {
                padding-top: 56.25%!important;
            }
        }
        .video-thubnail,.play-btn {
            opacity: 0; 
            visibility: hidden;
        }
        .video-data,&:after {
            opacity: 1;
            visibility: visible;
        }
    }
}`
const VideoData = styled.div`
    opacity: 0; 
    visibility: hidden;
}`
const PlayButton = styled.div`
    width: 5.1rem;
    height: 5.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; 
    position: absolute;
    font-size: 2.1rem;
    color: ${blue}; 
    top: 50%;
    left: 50%;
    transition: all 0.2s linear;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    border: 2px solid rgb(0,0,0,.1);
    box-shadow: 0 12px 20px rgba($color: #000000, $alpha: .08);
    margin: 0;
    .start-playing &{
      opacity: 0;
    }
    &:hover {
      border: 4px solid rgb(0,0,0,0.2);
    }
    .icon-play {
        position: relative;
        left: 2px;
    }
    @media (min-width: ${breakpointMd}) {
        border: 4px solid rgb(0,0,0,.1);
        width: 12.5rem;
        height: 12.5rem;
        font-size: 4rem;
    }
}`

const VideoBox = (props: any) => {
    const [playVideo, setPlayVideo] = React.useState(false);
    const  playVideoFn = () => {
        setPlayVideo(true)
    }
    return (
        <>
            <VideoContainer className={playVideo ? 'video-box position-relative playing': 'video-box position-relative '} >
                <div className='ratio ratio-16x9 '>
                    <img className='video-thubnail' src={props.thumbnail} alt={props.title} />
                    <VideoData className='video-data' >
                        <Vimeo
                            volume={1}
                            video={props.video}
                            paused={!playVideo}
                        />
                    </VideoData>
                </div>
                <PlayButton className='play-btn ' onClick={playVideoFn}>
                    <i className='icon-play'></i>
                </PlayButton>
            </VideoContainer>
        </>
    )
}

export default VideoBox
