import * as React from 'react'
import { graphql,Link } from 'gatsby'
import VideoBox from './common/video-box'
import CardImage from './common/card-image'
import Block from './common/block'
const TextMedia = (props: any) => {
   const data = props.data.text_media;  
   const reverse = props.reverse;
   const className = props.className? props.className: '';
  return (
    <>
        {/* thêm  'stand-alone' khi có 2 textmedia block khác màu đứng cạnh nhau  */}
        <Block className={'text-media-block ' + props.background + ' ' + className} >
            <div className='container'>
                <div className={reverse ? 'row align-items-center flex-row-reverse' : 'row align-items-center'}>
                    <div className='col-lg-6 media mb-5 mb-lg-0'>
                        {/* Option Image  */}
                        {
                            data.image ? (
                                <CardImage alt='' src={data.image}/>
                            ) : null
                        } 
                        
                        {/* Option video */}
                        {
                            data.video ? (
                                <VideoBox video={data.video.video} title={data.video.title} thumbnail={data.video.thumbnail}/>
                            ) : null
                        }
                        
                    </div>
                    <div className='col-lg-6 text' >
                        <div className='content-box'> 
                            <h3>{data.title}</h3>
                            <div className='content' dangerouslySetInnerHTML={{ __html: data.content }} />
                            {
                                data.links ? (
                                    <div className='action-buttons d-flex '>
                                    {
                                        data.links.map((link: any)=>(
                                            <a className='btn btn-outline-secondary' href={link.url} key={link.id}>{link.text} </a>
                                        ))
                                    }
                                </div>
                                ) : null
                            } 
                            
                        
                        </div>
                    </div>
                </div>
            </div>
        </Block>
    </>
  )
}

export default TextMedia
