import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import TextMedia from "../components/text-media"
import Title from "../components/title"
import StatsActivity from "../components/stats-activity"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import { Helmet } from 'react-helmet'
import IconLink from "../components/icon-link"
// markup 
const AboutPage = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter');
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media');
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero');
  const dataIconLink = _data.find((item: any) => item.block === 'icon-link');
  const statsActivity = _data.find((item: any) => item.block === 'stats-activity');
  console.log(_data);
  return (
    <Layout>
      <Helmet
        title='About'
      />
      <Hero background="bg-blue-white" data={hero.data[0]} container={true}></Hero>
      <Title data={dataTitle.data[0]} background="bg-white" ></Title>
      <IconLink data={dataIconLink.data[0]} background="bg-white"></IconLink>
      <StatsActivity data={statsActivity.data[0]}  background="bg-blue-bale"></StatsActivity>
      <Title data={dataTitle.data[1]}  background="bg-white"></Title>
      <TextMedia data={dataTextMedia.data[0]}   background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[1]} reverse={true}  background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[2]}    background="bg-white" ></TextMedia>  
      <TextMedia data={dataTextMedia.data[3]}  reverse={true}  background="bg-white" ></TextMedia>   
      <PreFooter data={dataPrefooter.data[0]}  background="bg-blue"></PreFooter> 
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["about"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            cards {
              id
              title
              image
              link
            }
            icons {
              title
              description
              icon_link {
                id
                title
                image
                link
              }
            }
            stats {
              stats_activity_title
              stats_activity {
                id
                activity_title
                activity_image
                activity_num
              }
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
                button_style
              }
            }
          }
        }
      }
    }
  }
`
export default AboutPage
