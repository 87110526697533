import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { blue } from '../../design/colors/brand'
import { HeadingSize1 } from '../../design/typography/size'
const Title = styled.div`
    font-weight: bold;
`;
const Number = styled.div`
    color: ${blue};
    font-size: ${HeadingSize1};
    font-weight: bold;
`;
const ImageContainer = styled.div`
    flex: 0 0 124px;
    max-width: 124px;   
`;

const StatsActivityItem = (props: any) => {
    const data = props.data || [];
    const number = data.activity_num || null
    const title = data.activity_title || null
    const image = data.activity_image || null
  return (
    <>
        <div className='stats-activity d-lg-flex align-items-center text-center text-lg-start'> 
            <ImageContainer className='stats-activity__img me-lg-4 pe-2 mx-auto mx-lg-0 mb-5 mb-lg-0'>
                <img  src={image} alt={data.alt}  />
            </ImageContainer>
            <div className='stats-activity__text'>
                <Title className='stats-activity__title'>{title}</Title>
                <Number className='stats-activity__number'>{number}</Number>
            </div>
        </div>
    </>
  )
}
export default StatsActivityItem
