import * as React from 'react'
import Slider from 'react-slick';
import Block from './common/block'

const Hero = (props: any) => { 
    const data = props.data.hero
    const container = props.container ? props.container : false
    const noSlider = props.noSlider ? 'no-slider' : ''
    const className = props.className ?  props.className : ''
    const settings = {
        speed: 500,
        arrows: false,
        autoplay: false,
        dots: false,
        cssEase: 'linear',
        autoplaySpeed: 5000,
        loop: false,
        centerMode:  container ? false : true,
        slidesToShow: container ? 4 : 2,
        variableWidth: container ? false : true,
        responsive: [
          {
            breakpoint: 991,
            settings: {
                slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                variableWidth: true,
                centerMode:  true
            }
          }
        ]
    };
  return (
    <>
        <Block className={'hero ' + `${container ? 'hero-container' : 'hero-full'}` + ' ' + props.background + ' ' + className  + ' ' + noSlider}>
        
            <div className="container-fluid g-0" >
                <div className="hero__top">
                    {
                        data.title_top ? (
                            <h5>{data.title_top}</h5>
                        ) : null
                    }
                    <h2 className="title mb-1">
                        {data.title}
                    </h2>
                    <div className='hero__description'  dangerouslySetInnerHTML={{__html: data.content}} />
                    {
                        data.hight_light ? (
                            <span className='hero__hight-light'>{data.hight_light}</span>
                        ) : null
                    }   
               
                    {
                    data.links ? (
                            <div className='mb-5 pb-4 d-flex align-items-center buttons justify-content-center'>
                            {
                                    data.links.map((link: any)=>(
                                        <a href={(link.url) ? link.url : ''} className='btn btn-outline-white' key={link.id}>{link.text}</a>
                                    ))
                                }
                            </div>
                        ) : null
                    }
                </div>
                {
                    data.images.length ? (
                        <div className='hero__middle'>
                            <div className='hero__slider-wrap'>
                                <div className={container ? 'container': 'hero__slider-wrap-inner'}>
                                    <Slider {...settings}>
                                    {
                                        data.images.map((image: any)=>(
                                            <div className='hero__item slick-slide' key={image.id}>
                                                <img width={image.width} src={image.url}/>
                                            </div>
                                        ))
                                    }
                                    </Slider>
                                </div>
                                
                            </div>
                        </div>
                    ) : null
                }
                
            </div>
        </Block>
    </>
  )
}

export default Hero
