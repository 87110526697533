import * as React from 'react'
import { graphql,Link } from 'gatsby'
// import '../assets/scss/main-style/components/title.scss'
import Block from './common/block'
const Title = (props: any) => {
    const data = props.data.title_data
    const standAlone =  props.standAlone ? ' stand-alone ' : ''
    const className = props.className ?  props.className : ''
  return (
    <>
        <Block className={'title-block text-center ' + props.background + ' ' + className +  standAlone}   >
            <div className='container'>
                <h2 dangerouslySetInnerHTML={{__html: data.title}} />
                {
                    data.content ? (
                        <div className='title-block-text'  dangerouslySetInnerHTML={{__html: data.content}}/>
                    ) : null
                }
                 {
                    data.links ? (
                        <div className='d-flex align-items-center buttons justify-content-center'>
                           {
                                data.links.map((link: any)=>(
                                    <a href={(link.url) ? link.url : ''} className='btn btn-outline-secondary' key={link.id}>{link.text}</a>
                                ))
                            }
                        </div>
                    ) : null
                }
            </div> 
        </Block> 
    </>
  )
}

export default Title
